@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
    font-family: "Roc Grotesk";
    src: url("../fonts/Roc-Grotesk-Light.woff2") format("woff2"),
    url("../fonts/Roc-Grotesk-Light.woff") format("woff");
    font-weight: 300;
}

@font-face {
    font-family: "Roc Grotesk";
    src: url("../fonts/Roc-Grotesk-Regular.woff2") format("woff2"),
    url("../fonts/Roc-Grotesk-Regular.woff") format("woff");
    font-weight: 400;
}

@font-face {
    font-family: "Roc Grotesk";
    src: url("../fonts/Roc-Grotesk-Medium.woff2") format("woff2"),
    url("../fonts/Roc-Grotesk-Medium.woff") format("woff");
    font-weight: 600;
}


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --typography-sans: 'Roc Grotesk';
        --typography-serif: 'Roc Grotesk';
        --typography-heading: 'Roc Grotesk';
        --typography-footer: 'Noto Sans';
    }

    html {
        @apply antialiased bg-sand;
        scroll-behavior: smooth;
    }

    body {
        @apply font-light text-xl leading-snug md:leading-normal lg:leading-relaxed min-h-screen bg-sand cursor-default;

        * {
            //position: relative;
        }
    }

    h1, h2, h3, h4,
    h5 {
        @apply font-heading font-light text-2xl md:text-5xl lg:text-7xl text-sand-darker;
    }

    a {
        @apply underline transition;

        &:where(.h-12):has(svg) {
            svg {
                @apply h-full;
            }
        }
    }
}

@layer utilities {
}

@layer components {
    .heading {
        &--xxl {
            @apply text-4xl md:text-6xl lg:text-8xl xxl:text-9xl tracking-[-0.025em];
        }
    }

    form.input {

        input, textarea {

            @apply bg-white/30 border-b text-gray border-primary accent-primary focus:outline-none focus:ring focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:bg-white transition;

            &:not([type="checkbox"]):not([type="radio"]) {
                @apply w-full;
            }

            &[type="checkbox"],
            &[type="radio"] {
                @apply h-4 w-4 mr-2 border-primary border-none focus:ring-0 mt-2;
            }

            &[type="radio"] {
                @apply rounded-full bg-red-500;
            }

            &--file {
                @apply file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-primary/20 file:text-primary hover:file:bg-primary/40 transition;
            }

            &__label {
                &--required {
                    @apply after:content-['*'] after:text-red-800 after:ml-1;
                }
            }

            &--error {
                @apply border-red-900;

                &list {
                    @apply m-0 col-span-2 pl-4;
                }
            }
        }

        label {
            @apply block text-sm relative;

            span {
                @apply block ;
            }


        }
    }

    .button {
        @apply text-gray-darker uppercase relative px-0 pt-1 hover:px-2 before:content-[''] relative z-10 overflow-hidden inline-block hover:text-white tracking-wide text-center no-underline;
        transition: all .15s ease-in;


        &:before {
            @apply absolute block inset-0 origin-bottom bg-gradient-to-r from-gradient-start to-gradient-end -z-10 translate-y-[calc(100%-2px)] transition;
        }

        &:hover:before {
            @apply translate-y-0;
        }

        &--active {
            @apply px-2 text-white;

            &:before {
                @apply translate-y-0;
            }
        }

        &--small {
            @apply text-left text-base mr-4;

            &:hover {
                @apply mr-0;
            }
        }
    }

    main a {
        @apply hover:text-primary transition;
    }


    .nav__item {
        @apply relative text-base h-6 block z-100;

        &:after {
            @apply content-[attr(title)] text-sand-darker absolute whitespace-nowrap text-center left-1/2 -translate-x-1/2;
            transition: all .15s ease-in;
        }

        &:hover:after {
            @apply tracking-wider font-normal;
        }

        &:hover {
            + .dropdown {
                @apply block;
            }
        }

        &--active {
            @apply before:content-[''];

            &:after {
                @apply content-[attr(title)] text-sand-darker font-bold;
                transition: all .15s ease-in;
            }

            &:before {
                @apply absolute block inset-x-0 h-0.5 bottom-0 origin-bottom bg-gradient-to-r from-gradient-start to-gradient-end -z-10;
            }
        }

        &--mobile {
            @apply relative text-base whitespace-nowrap px-4 no-underline;

            &:after {
                @apply content-[attr(title)] text-white absolute whitespace-nowrap text-center left-1/2 -translate-x-1/2;
                transition: all .15s ease-in;
            }

            &:hover:after {
                @apply tracking-wider font-normal;
            }

            &--active {
                &:after {
                    @apply content-[attr(title)] text-white font-bold;
                    transition: all .15s ease-in;
                }
            }
        }
    }

    blockquote {
        @apply font-light text-3xl md:text-4xl;
    }

    ul, ol {
        @apply list-disc ml-6;
    }

    .nav--side {
        @apply fixed top-[calc(50%)] -translate-y-1/2 right-0 bg-sand/60 backdrop-blur-lg p-4 z-100 grid gap-4 translate-x-[calc(100%-74px)] transition hover:translate-x-0;

        &:has(a:hover) {
            a:not(:hover) {
                @apply opacity-50;
            }
        }
    }
}

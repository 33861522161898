@import url('../../css/_base.pcss');

@layer base {
    :root {
        --color-primary-lighter: 125.91, 91%, 54%;
        --color-primary-light: 125.91, 91%, 44%;
        --color-primary: 141, 91%, 34%;
        --color-primary-dark: 125.91, 91%, 24%;
        --color-primary-darker: 125.91, 91%, 14%;

        --color-gradient-start: 141, 91%, 35%;
        --color-gradient-end: 209, 95%, 36%;
    }
}

